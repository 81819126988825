<template>
    <v-footer padless bottom fixed dark app class="grey darken-4">
        <v-col class="text-center" cols="12">
            Lensing code adapted from <a href="https://github.com/oseiskar/black-hole">oseiskar/black-hole</a> and <a href="https://github.com/peabrainiac/black-hole-renderer">peabrainiac/black-hole-renderer</a>
        </v-col>
    </v-footer>
</template>

<script>
export default {
    name:'CopyRightFooter'
}
</script>